<template>
  <pimples-template
    :prone-to-acne="proneToAcne"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PimplesTemplate from '@/modules/questionnaire/components/steps/questionnaire/pimples/pimples/PimplesTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const fieldsToReset = [
  'facialAcneCoverage',
  'acneTypes',
  'concurrentNodules',
  'acneDiscomfortFrequency',
  'firstAcneOccurrence',
  'acneDiscomfort'
];

const FIELDS = [...fieldsToReset.map(field), requiredField('proneToAcne')];

export default {
  name: 'Pimples',
  components: {
    PimplesTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    proneToAcne(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      this.showNextStep();
    }
  }
};
</script>
